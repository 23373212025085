<template>
  <div class="auth-bg min-h-screen h-max lg:flex">
    <auth-layout/>
    <div class="w-full h-max pb-10 lg:ml-1/2 px-4 sm:px-6 lg:px-12 xl:pl-24 xl:pr-20 lg:mt-16 lg:max-w-xxl">
      <a href="https://loanspot.africa" class="block w-max mx-auto lg:hidden mt-8 cursor-pointer">
        <img src="/assets/images/LoanSpot-logo_white.svg" />
      </a>
      <div class="mt-8 lg:mt-0 bg-white px-5 sm:px-6 lg:px-0 rounded-xl lg:rounded-none">
        <h3 class="big-title text-5 xl:text-8 text-title pt-8 lg:pt-0">
          Get Started with Loanspot
        </h3>
        <p class="text-sm text-sec-text mt-1 xl:mt-2 xxs:w-72 xl:w-full">
          Let’s get you all set up so you begin getting the best loan offers.
        </p>
        <form @submit.prevent="register" class="mt-10">
          <div class="sm:grid-cols-2 sm:grid sm:gap-6">
            <div class="flex flex-col input-wrap">
              <label class="text-xs inline-block text-title title">First Name</label>
              <div class="flex items-center w-full rounded-lg mt-1">
                <svg class="absolute ml-4" width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 14C0 12.5855 0.561903 11.229 1.5621 10.2288C2.56229 9.22857 3.91885 8.66667 5.33333 8.66667C6.74782 8.66667 8.10437 9.22857 9.10457 10.2288C10.1048 11.229 10.6667 12.5855 10.6667 14H9.33333C9.33333 12.9391 8.91191 11.9217 8.16176 11.1716C7.41161 10.4214 6.3942 10 5.33333 10C4.27247 10 3.25505 10.4214 2.50491 11.1716C1.75476 11.9217 1.33333 12.9391 1.33333 14H0ZM5.33333 8C3.12333 8 1.33333 6.21 1.33333 4C1.33333 1.79 3.12333 0 5.33333 0C7.54333 0 9.33333 1.79 9.33333 4C9.33333 6.21 7.54333 8 5.33333 8ZM5.33333 6.66667C6.80667 6.66667 8 5.47333 8 4C8 2.52667 6.80667 1.33333 5.33333 1.33333C3.86 1.33333 2.66667 2.52667 2.66667 4C2.66667 5.47333 3.86 6.66667 5.33333 6.66667Z" fill="#6D7D93"/>
                </svg>
                <input
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-10"
                  type="text"
                  name="First Name"
                  autocomplete="off"
                  required
                  v-model="formBody.firstName"
                  placeholder="Omogiadhale"
                />
              </div>
            </div>
            <div class="flex flex-col sm:mt-0 mt-8">
              <label class="text-xs inline-block text-title title">Last Name</label>
              <div class="flex items-center w-full rounded-lg mt-1">
                <svg class="absolute ml-4" width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 14C0 12.5855 0.561903 11.229 1.5621 10.2288C2.56229 9.22857 3.91885 8.66667 5.33333 8.66667C6.74782 8.66667 8.10437 9.22857 9.10457 10.2288C10.1048 11.229 10.6667 12.5855 10.6667 14H9.33333C9.33333 12.9391 8.91191 11.9217 8.16176 11.1716C7.41161 10.4214 6.3942 10 5.33333 10C4.27247 10 3.25505 10.4214 2.50491 11.1716C1.75476 11.9217 1.33333 12.9391 1.33333 14H0ZM5.33333 8C3.12333 8 1.33333 6.21 1.33333 4C1.33333 1.79 3.12333 0 5.33333 0C7.54333 0 9.33333 1.79 9.33333 4C9.33333 6.21 7.54333 8 5.33333 8ZM5.33333 6.66667C6.80667 6.66667 8 5.47333 8 4C8 2.52667 6.80667 1.33333 5.33333 1.33333C3.86 1.33333 2.66667 2.52667 2.66667 4C2.66667 5.47333 3.86 6.66667 5.33333 6.66667Z" fill="#6D7D93"/>
                </svg>
                <input
                  class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-10"
                  type="text"
                  name="Last Name"
                  autocomplete="off"
                  required
                  v-model="formBody.lastName"
                  placeholder="Adeniji-Fashola"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-col mt-8">
            <label class="text-2 text-xs inline-block text-title title">Email Address
            </label>
            <div class="flex items-center w-full rounded-lg mt-1">
              <svg class="absolute ml-4" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.666667 0H12.6667C12.8435 0 13.013 0.0702379 13.1381 0.195262C13.2631 0.320286 13.3333 0.489856 13.3333 0.666667V11.3333C13.3333 11.5101 13.2631 11.6797 13.1381 11.8047C13.013 11.9298 12.8435 12 12.6667 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333V0.666667C0 0.489856 0.0702379 0.320286 0.195262 0.195262C0.320286 0.0702379 0.489856 0 0.666667 0ZM12 2.82533L6.71467 7.55867L1.33333 2.81067V10.6667H12V2.82533ZM1.674 1.33333L6.70733 5.77467L11.668 1.33333H1.674Z" fill="#6D7D93"/>
              </svg>
              <input
                class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-10"
                type="email"
                name="Email"
                autocomplete="off"
                required
                v-model="formBody.email"
                placeholder="adeniji-fashola@gmail.com"
              />
            </div>
          </div>

          <div class="flex flex-col mt-8">
            <label class="text-xs inline-block text-title title">Phone Number</label>
            <div class="flex items-center w-full rounded-lg mt-1">
              <svg class="absolute ml-4" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.244 5.12133C4.86956 6.22032 5.77968 7.13044 6.87867 7.756L7.468 6.93067C7.56277 6.79796 7.7029 6.70459 7.86187 6.66822C8.02084 6.63186 8.18762 6.65502 8.33067 6.73333C9.27355 7.24862 10.3148 7.55852 11.386 7.64267C11.5532 7.65592 11.7092 7.73169 11.823 7.85488C11.9368 7.97807 12 8.13963 12 8.30733V11.282C12 11.4471 11.9388 11.6063 11.8282 11.7288C11.7177 11.8513 11.5655 11.9285 11.4013 11.9453C11.048 11.982 10.692 12 10.3333 12C4.62667 12 0 7.37333 0 1.66667C0 1.308 0.018 0.952 0.0546667 0.598667C0.0715031 0.434465 0.148656 0.282347 0.271193 0.171756C0.39373 0.0611648 0.552937 -3.55718e-05 0.718 1.55115e-08H3.69267C3.86037 -2.10123e-05 4.02193 0.0631677 4.14512 0.176967C4.26831 0.290767 4.34408 0.446816 4.35733 0.614C4.44148 1.68519 4.75138 2.72645 5.26667 3.66933C5.34498 3.81238 5.36814 3.97916 5.33178 4.13813C5.29541 4.2971 5.20204 4.43723 5.06933 4.532L4.244 5.12133ZM2.56267 4.68333L3.82933 3.77867C3.46986 3.00273 3.22357 2.17923 3.098 1.33333H1.34C1.336 1.444 1.334 1.55533 1.334 1.66667C1.33333 6.63733 5.36267 10.6667 10.3333 10.6667C10.4447 10.6667 10.556 10.6647 10.6667 10.66V8.902C9.82077 8.77643 8.99727 8.53014 8.22133 8.17067L7.31667 9.43733C6.95244 9.29581 6.59867 9.12873 6.258 8.93733L6.21933 8.91533C4.91172 8.17115 3.82885 7.08828 3.08467 5.78067L3.06267 5.742C2.87127 5.40133 2.70419 5.04756 2.56267 4.68333Z" fill="#6D7D93"/>
              </svg>
              <input
                v-model="formBody.phoneNumber"
                class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-4 pl-10"
                type="number"
                name="Phone"
                autocomplete="off"
                required
                placeholder="0801 234 6789"
              />
            </div>
          </div>

          <div class="flex flex-col input-wrap mt-8">
            <label class="text-xs inline-block text-title title">Password</label>
            <div class="flex items-center w-full rounded-lg mt-1">
              <svg class="absolute ml-4" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.6667 5.33333H11.3333C11.5101 5.33333 11.6797 5.40357 11.8047 5.5286C11.9298 5.65362 12 5.82319 12 6V12.6667C12 12.8435 11.9298 13.013 11.8047 13.1381C11.6797 13.2631 11.5101 13.3333 11.3333 13.3333H0.666667C0.489856 13.3333 0.320286 13.2631 0.195262 13.1381C0.0702379 13.013 0 12.8435 0 12.6667V6C0 5.82319 0.0702379 5.65362 0.195262 5.5286C0.320286 5.40357 0.489856 5.33333 0.666667 5.33333H1.33333V4.66667C1.33333 4.05383 1.45404 3.447 1.68856 2.88081C1.92308 2.31462 2.26683 1.80018 2.70017 1.36683C3.13351 0.933495 3.64796 0.589751 4.21414 0.355229C4.78033 0.120707 5.38716 0 6 0C6.61284 0 7.21967 0.120707 7.78586 0.355229C8.35204 0.589751 8.86649 0.933495 9.29983 1.36683C9.73317 1.80018 10.0769 2.31462 10.3114 2.88081C10.546 3.447 10.6667 4.05383 10.6667 4.66667V5.33333ZM1.33333 6.66667V12H10.6667V6.66667H1.33333ZM5.33333 8H6.66667V10.6667H5.33333V8ZM9.33333 5.33333V4.66667C9.33333 3.78261 8.98214 2.93477 8.35702 2.30964C7.7319 1.68452 6.88406 1.33333 6 1.33333C5.11595 1.33333 4.2681 1.68452 3.64298 2.30964C3.01786 2.93477 2.66667 3.78261 2.66667 4.66667V5.33333H9.33333Z" fill="#6D7D93"/>
              </svg>
              <input
                class="bg-transparent w-full h-12 text-title border outline-none rounded-lg text-sm pr-10 pl-10"
                :type="showPassword ? 'password' : 'text'"
                required
                autocomplete="off"
                v-model="formBody.password"
                placeholder="••••••••"
              />
              <span
                ref="passwordShow"
                @click.prevent="showPassword = !showPassword"
                class="cursor-pointer text-xs text-sec-text -ml-12 z-50 input-icon"
              >
                {{ showPassword ? "Show" : "Hide" }}
              </span>
            </div>
          </div>

          <p
            class="my-6 text-accent-3 text-xs text-center w-64 m-auto"
          >
            By opening an account, you are accepting our
            <a
              href="https://terms.com"
              class="underline hover:text-secondary focus:text-secondary focus:outline-none"
              >Terms of Use</a >
            and
            <a
              href="https:policy.com"
              class="underline hover:text-secondary focus:text-secondary focus:outline-none"
            >Privacy Policy.</a>
          </p>

          <Button
            id="register-btn"
            customClass="focus:outline-none flex items-center justify-center bg-primary w-full rounded-lg h-14 hover:bg-bg-hover text-white font-medium text-sm tracking-wider"
            :isDisabled="isRequest === true"
          >
            <span
              slot="spin"
              class="animate-spin"
              :class="spinner ? '' : 'hidden'"
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" d="M13.75 7C13.75 10.7279 10.7279 13.75 7 13.75C3.27208 13.75 0.25 10.7279 0.25 7C0.25 3.27208 3.27208 0.25 7 0.25C10.7279 0.25 13.75 3.27208 13.75 7ZM1.6 7C1.6 9.98234 4.01766 12.4 7 12.4C9.98234 12.4 12.4 9.98234 12.4 7C12.4 4.01766 9.98234 1.6 7 1.6C4.01766 1.6 1.6 4.01766 1.6 7Z" fill="#EFEFEF"/>
                <path d="M7 13.075C7 13.4478 6.69704 13.7535 6.32611 13.7163C5.37081 13.6204 4.44421 13.3216 3.60954 12.8367C2.57975 12.2385 1.72655 11.3785 1.13655 10.344C0.546549 9.3095 0.240758 8.1373 0.250213 6.94641C0.259668 5.75552 0.584032 4.58833 1.19038 3.56331C1.79673 2.5383 2.66348 1.69195 3.70264 1.11018C4.74181 0.528418 5.91639 0.231942 7.10717 0.250851C8.29795 0.26976 9.46253 0.60338 10.4827 1.21785C11.3096 1.71589 12.0177 2.38405 12.5617 3.17514C12.773 3.48231 12.6509 3.89503 12.3242 4.07455C11.9975 4.25407 11.5904 4.13163 11.3715 3.82984C10.9474 3.24504 10.4089 2.74935 9.78616 2.37428C8.97003 1.8827 8.03836 1.61581 7.08574 1.60068C6.13311 1.58555 5.19345 1.82273 4.36211 2.28815C3.53078 2.75356 2.83738 3.43064 2.3523 4.25065C1.86723 5.07066 1.60773 6.00442 1.60017 6.95713C1.59261 7.90984 1.83724 8.8476 2.30924 9.67521C2.78124 10.5028 3.4638 11.1908 4.28763 11.6694C4.91623 12.0345 5.61 12.2678 6.32674 12.3579C6.69663 12.4043 7 12.7022 7 13.075Z" fill="white"/>
              </svg>
            </span>
            <span slot="value" :class="spinner ? 'hidden' : 'title'">
              {{ signUpBtnText }}
            </span>
          </Button>
        </form>
        <p class="text-accent-3 text-sm pt-6 pb-10 text-center">Already have an account? <a href="/login" class="text-primary title">Login</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../components/shared/button";
// import { ApiInstance as api } from '../../utils';
import AuthLayout from '../../components/auth/auth-layout.vue';
import mixpanel from 'mixpanel-browser';
import axios from "axios";
import { BASE_API } from "../../constants";
import Cookies from 'js-cookie';

export default {
  name: 'Signup',
  components: {
    AuthLayout,
    Button
  },
  data() {
    return {
      showPassword: true,
      isRequest: false,
      spinner: false,
      date: "DD/MM/YYYY",
      signUpBtnText: "Register",
      formBody: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        userType: "individual",
        referralCode: "",
      },
    }
  },
  methods: {
    async register() {
      this.isRequest = true;
      this.spinner = true;
      const registerBtn = document.getElementById("register-btn");
      try {
        const response = await axios.post(
          `${BASE_API}/auth/register`,
          this.formBody
        );
        if (response.status === 201) {
          localStorage.setItem('auth-token', response.data.data.token)
          mixpanel.alias(this.formBody.email, {
            'distinct_id': Cookies.get('mix_id')
          });
          mixpanel.track('Signup successful', {
            'distinct_id': Cookies.get('mix_id')
          });
          this.$notie.alert({
            type: "success",
            text: "Sign up successful. Please verify your phone number",
            time: 2,
          });
          this.spinner = false;
          this.$router.replace("/verification/phone");
        }
      } catch (error) {
        let message =
          error.response.data?.message || "Registeration not successful";
        if ([409, 422, 400].includes(error.response?.data.statusCode)) {
          let errorFields =
            error.response.data.fields || error.response.data.errors || {};
          if (Object.keys(errorFields).length > 0) {
            this.errors = errorFields;
            message = Object.values(errorFields)[0];
          }
        }
        this.spinner = false;
        this.signUpBtnText = message;
        registerBtn.style.fontSize = "12px";
        registerBtn.style.backgroundColor = "#F23204";
        setTimeout(() => {
          this.signUpBtnText = "Register";
          registerBtn.style.fontSize = "14px";
          registerBtn.style.backgroundColor = "#1656c1";
          this.isRequest = false;
        }, 3000);
      }
      // const response = await api.post('/auth/register', this.formBody);
      
      // if(response.error) {
      //   this.isRequest = false;
      //   this.spinner = false;
      //   return this.$notie.alert({
      //     type: "error",
      //     // text: Object.values(response.errors),
      //     time: 3,
      //   });
      // }
      // if(response.data) {
      //   this.isRequest = false;
      //   this.spinner = false;
      //   localStorage.setItem('auth-token', response.data.data.token)
      //   this.$notie.alert({
      //     type: "success",
      //     text: "Sign up successful. Please verify your phone number",
      //     time: 2,
      //   });
      //   setTimeout(() => {
      //     this.$router.replace('/verification/phone')
      //   }, 2000);
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
  .auth-bg {
    background: linear-gradient(154.89deg, #5B7BFD -0.2%, #3752C1 82.72%);
    @media (min-width: 1024px) {
      background: #fff;
    }
  }
  .big-title {
    font-family: SohneHalbfett, sans-serif;
  }
  .title {
    font-family: SohneKraftig, sans-serif;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::placeholder {
    font-family: SohneLeicht;
    font-size: 0.875rem;
  }
</style>