<template>
  <div class="flex">
    <div :class="login ? 'lg:right-0' : ''" class="hidden lg:block lg:fixed lg:h-screen w-1/2 bg__grad">
      <div :class="login ? 'mr-auto' : 'ml-auto'" class="pt-10 w-full max-w-xxl">
        <a :class="login ? 'hidden' : ''" class="ml-20 block cursor-pointer">
          <img src="/assets/images/LoanSpot-logo_white.svg" alt="" class="" />
        </a>

        <div class="w-full mt-10 xxl:mt-20 mx-auto signup_img">
          <div class="flex items-center justify-end w-full rounded-t-2xl px-6 bg-bg-signup h-9">
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill="white"/>
            </svg>
            <svg class="mx-1" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill="white"/>
            </svg>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="4" cy="4" r="4" fill="white"/>
            </svg>
          </div>

          <div class="px-6 pb-10 rounded-b-2xl bg-white">
            <h4 class="title pt-6">Available Loans</h4>
            <div class="border-b border-input">
              <div class="mt-6 flex">
                <h4 class="py-4 w-1/2 text-center title text-primary">Individual</h4>
                <h4 class="py-4 w-1/2 text-center title text-accent-3">Business</h4>
              </div>
              <div class="h-0.5 bg-primary w-1/2"></div>
            </div>
            <div class="mt-8 grid grid-cols-3 gap-4">
              <div class="rounded-lg border border-input px-3 pb-4 animate-slideTop">
                <h4 class="title text-center mt-6">{{ boxes.one }}</h4>
                <div class="mt-5 grid gap-3">
                  <div class="grid gap-1">
                    <div class="h-2.5 bg-signup-box rounded animate-pulse"></div>
                    <div class="grid grid-cols-4 gap-1">
                      <div class="h-2.5 bg-signup-box rounded col-span-3"></div>
                      <div class="h-2.5 bg-signup-box rounded"></div>
                    </div>
                    <div class="grid grid-cols-4 gap-1">
                      <div class="h-2.5 bg-signup-box rounded"></div>
                      <div class="h-2.5 bg-signup-box rounded col-span-3"></div>
                    </div>
                    <div class="h-2.5 bg-signup-box rounded"></div>
                  </div>
                  <div class="h-6 rounded bg-signup-box animate-pulse"></div>
                </div>
              </div>
              <div class="rounded-lg border border-input px-3 pb-4 animate-slideTop">
                <h4 class="title text-center mt-6">{{ boxes.two }}</h4>
                <div class="mt-5 grid gap-3">
                  <div class="grid gap-1">
                    <div class="h-2.5 bg-signup-box rounded"></div>
                    <div class="grid grid-cols-4 gap-1 animate-pulse">
                      <div class="h-2.5 bg-signup-box rounded col-span-3"></div>
                      <div class="h-2.5 bg-signup-box rounded"></div>
                    </div>
                    <div class="grid grid-cols-4 gap-1">
                      <div class="h-2.5 bg-signup-box rounded"></div>
                      <div class="h-2.5 bg-signup-box rounded col-span-3"></div>
                    </div>
                    <div class="h-2.5 bg-signup-box rounded animate-pulse"></div>
                  </div>
                  <div class="h-6 rounded bg-signup-box"></div>
                </div>
              </div>
              <div class="rounded-lg border border-input px-3 pb-4">
                <h4 class="title text-center mt-6">{{ boxes.three }}</h4>
                <div class="mt-5 grid gap-3">
                  <div class="grid gap-1">
                    <div class="h-2.5 bg-signup-box rounded"></div>
                    <div class="grid grid-cols-4 gap-1">
                      <div class="h-2.5 bg-signup-box rounded col-span-3"></div>
                      <div class="h-2.5 bg-signup-box rounded"></div>
                    </div>
                    <div class="grid grid-cols-4 gap-1">
                      <div class="h-2.5 bg-signup-box rounded"></div>
                      <div class="h-2.5 bg-signup-box rounded col-span-3"></div>
                    </div>
                    <div class="h-2.5 bg-signup-box rounded"></div>
                  </div>
                  <div class="h-6 rounded bg-signup-box"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center">
          <h2 class="text-white text-8 mt-10 text-center title">Easy & Safe to use</h2>
          <p class="text-white opacity-60 w-62 mt-2 xxl:mt-4 text-center">Apply for loans quickly. Easily apply for multiple loans in no time.</p>
        </div>

        <div class="flex items-center mt-3 xxl:mt-6 w-max mx-auto">
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#B8C2EB"/>
          </svg>
          <svg class="mx-1.5" width="32" height="8" viewBox="0 0 32 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="8" rx="4" fill="white"/>
          </svg>
          <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="#B8C2EB"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AuthLayout',
  components: {
    // Signup,
    // Setup,
    // Login
  },
  created() {
    if(this.$route.path === '/signup') this.signup = true;
    if(this.$route.path === '/setup/complete') this.complete = true;
    if(this.$route.path === '/login') this.login = true;
  },
  data() {
    return {
      signup: false,
      complete: false,
      login: false,
      boxes: {
        one: 'Paycheck',
        two: 'Housing',
        three: 'Medical'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .bg__grad {
    background: linear-gradient(154.89deg, #5B7BFD -0.2%, #3752C1 82.72%);
  }
  .signup_img {
    width: 400px;
    @media (min-width: 1280px) {
      width: 458px;
    }
  }
  .title {
    font-family: SohneKraftig, sans-serif;
  }
</style>